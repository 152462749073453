<template>
  <div class="collapsible-form custom-box">
    <div class="d-flex justify-content-between align-items-center mb-2">
      <label v-if="ExibirNrCartoes" class="mb-0 font-weight-bold"
        >Cartão {{ numeroCartao }}</label
      >
      <div class="collapse-button" @click="reduzirLocal = !reduzirLocal">
        <b-icon
          :icon="reduzirLocal ? 'caret-down-fill' : 'caret-up-fill'"
        ></b-icon>
      </div>
    </div>

    <transition name="fade">
      <div v-show="!reduzirLocal" class="form-content">
        <!-- Conteúdo do formulário aqui -->
        <div class="row">
          <input-currency
            id="input-valor"
            class="col-12 col-md-6 custom-white"
            :label="$t('CHECKOUT.VALOR')"
            ref="valor"
            required
            v-model="form.valor"
            :disabled="disableValor"
            @input="handleValorInput"
          />

          <input-currency
            id="input-valor-parcela"
            class="col-12 col-md-6"
            :label="$t('CHECKOUT.VALOR_PARCIAL')"
            ref="valorParcela"
            v-model="form.valorParcela"
            disabled
          />
        </div>

        <div class="row">
          <input-mask
            class="col-12 col-md-4 custom-white"
            v-model="form.numeroDoCartao"
            :mask="['#### #### #### ####']"
            :label="$t('CHECKOUT.NUMERO_CARTAO')"
            ref="numeroDoCartao"
            required
          />

          <input-select
            class="col-12 col-md-2"
            :options="opcoesMeses"
            v-model="form.mesVencimento"
            :label="$t('CHECKOUT.MES_VENCIMENTO')"
            ref="mesVencimento"
            required
          >
          </input-select>

          <input-select
            class="col-12 col-md-3"
            :options="opcoesAno"
            v-model="form.anoVencimento"
            :label="$t('CHECKOUT.ANO_VENCIMENTO')"
            ref="anoVencimento"
            required
          >
          </input-select>

          <input-mask
            class="col-12 col-md-3 custom-white"
            v-model="form.codigoSeguranca"
            :label="$t('CHECKOUT.CODIGO_SEGURANCA')"
            :mask="['###', '####']"
            ref="codigoSeguranca"
            required
          />
        </div>

        <div class="row">
          <input-text
            class="col-12 col-md-4 custom-white"
            v-model="form.nomeTitular"
            :label="$t('CHECKOUT.NOME_CARTAO')"
            ref="nomeTitular"
            required
          />

          <input-mask
            class="col-12 col-md-4 custom-white"
            v-model="form.cpfCnpj"
            :mask="['###.###.###-##', '###.###.###-##']"
            :label="$t('CHECKOUT.CPF_CNPJ')"
            isDocument
            ref="cpfCnpj"
            required
          />

          <input-mask
            class="col-12 col-md-4 custom-white"
            v-model="form.telefone"
            :label="$t('CHECKOUT.TELEFONE')"
            :mask="['(##) #####-####', '(##) ####-####']"
            ref="telefone"
          />
        </div>

        <div v-if="ExibirEndereco" class="div-endereco">
          <div class="tagComprador label-comprador">
            {{ $t("CHECKOUT.COBRANCA") }}
          </div>
          <div class="row">
            <input-mask
              class="col-12 col-md-4 custom-white"
              v-model="form.cep"
              :mask="['#####-###']"
              :label="$t('CHECKOUT.CEP')"
              @change="consultaCep"
              ref="cep"
            />

            <input-text
              class="col-12 col-md-6 custom-white"
              v-model="form.logradouro"
              :label="$t('CHECKOUT.LOGRADOURO')"
              ref="logradouro"
            />

            <input-text
              class="col-12 col-md-2 custom-white"
              v-model="form.numero"
              :label="$t('CHECKOUT.NUMERO')"
              ref="numero"
            />
          </div>

          <div class="row">
            <input-text
              class="col-12 col-md-4 custom-white"
              v-model="form.bairro"
              :label="$t('CHECKOUT.BAIRRO')"
              ref="bairro"
            />

            <input-text
              class="col-12 col-md-4 custom-white"
              v-model="form.cidade"
              :label="$t('CHECKOUT.CIDADE')"
              ref="cidade"
            />

            <input-select
              class="col-12 col-md-4 custom-white"
              :options="opcoesEstado"
              v-model="form.uf"
              :label="$t('CHECKOUT.UF')"
              ref="uf"
            ></input-select>
          </div>

          <div class="row">
            <input-text
              class="col-12 custom-white"
              v-model="form.complemento"
              :label="$t('CHECKOUT.COMPLEMENTO')"
              ref="complemento"
            />
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import {
  InputText,
  InputMask,
  InputSelect,
  InputCurrency,
} from "@/components/inputs";
import helpers from "@/common/utils/helpers";
import cep from "cep-promise";

export default {
  components: {
    InputText,
    InputMask,
    InputSelect,
    InputCurrency,
  },
  props: {
    ExibirNrCartoes: {
      type: Boolean,
      default: false,
    },
    numeroCartao: {
      type: String,
      default: "",
    },
    reduzir: {
      type: Boolean,
      default: false,
    },
    disableValor: {
      type: Boolean,
      default: false,
    },
    numeroParcelas: {
      type: Number,
      default: 0,
    },
    ExibirEndereco: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        valor: null,
        valorParcela: null,
      },
      cep: null,
      logradouro: null,
      numero: null,
      complemento: null,
      bairro: null,
      cidade: null,
      uf: null,
      reduzirLocal: this.reduzir,
      opcoesMeses: helpers.Meses,
      opcoesAno: helpers.Anos,
      opcoesEstado: helpers.Estados,
    };
  },
  watch: {
    numeroParcelas() {
      this.calcularValorParcela(this.form.valor);
    },
  },
  methods: {
    calcularValorParcela(valor) {
      if (valor != 0 && valor != "0") {
        let valorNum = helpers.removerConverterValor(valor);
        let valorParcela = helpers.formatarValor(
          valorNum / this.numeroParcelas
        );

        this.form.valorParcela = valorParcela;
      }
    },
    handleValorInput(novoValor) {
      this.$emit("calcularValorDemaisCartoes", this.numeroCartao);
      this.calcularValorParcela(novoValor);
    },
    consultaCep: function () {
      const value = helpers.removerMascara(this.form.cep);

      var validacep = /^[0-9]{8}$/;
      if (validacep.test(value)) {
        cep(value).then((res) => {
          this.form.cep = res.cep;
          this.form.logradouro = res.street;
          this.form.bairro = res.neighborhood;
          this.form.cidade = res.city;
          this.form.uf = res.state;
        });
      }
    },
  },
};
</script>

<style>
.custom-box {
  border: 1px solid #ccc;
  background-color: #f9f9f9;
  padding: 10px 10px 10px 10px;
}

.custom-white input {
  background-color: #ffffff;
}

.collapsible-form {
  margin-bottom: 5px;
}

.collapse-button {
  cursor: pointer;
  margin-bottom: 0.5rem;
}

.form-content {
  max-height: 200;
  overflow: hidden;
  transition: max-height 0.3s;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.div-endereco {
  padding: 5px 20px;
  border-bottom: 5px;
  border: #ccc 1px solid;
}
</style>