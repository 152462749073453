
export class Checkout {
  constructor({
    id,
    valorTotal,
    valor,
    numeroDeParcelas,
    qtdeCartoes,
    cartoes
  }) {
    this.id = id;
    this.valorTotal = valorTotal;
    this.valor = valor;
    this.numeroDeParcelas = numeroDeParcelas;
    this.qtdeCartoes = qtdeCartoes;
    this.cartoes = cartoes;
  }
}
